<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{path:'/illnessManage'}">疾病管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
            <el-breadcrumb-item>好转病例</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <el-button @click="addCase('new','')" size="small">添加</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column label="年龄" prop="age"></el-table-column>
            <el-table-column label="性别" prop="sex"></el-table-column>
            <el-table-column label="疾病类别" prop="ill_name"></el-table-column>
            <el-table-column label="所患疾病" prop="ill"></el-table-column>
            <el-table-column label="阅读数" prop="view_count"></el-table-column>
            <el-table-column label="评论数" prop="comment_count"></el-table-column>
            <el-table-column label="点赞数" prop="like_count"></el-table-column>
            <el-table-column width="300">
                <template slot-scope="scope">
                    <div class="opt">
                        <el-button size="mini" type="primary" icon="el-icon-edit" @click="addCase('bj',scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">删除 </el-button>
                        <el-button size="mini" type="warning" icon="el-icon-view" @click="lookLY(scope.row,scope.$index)">查看评论 </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
        <el-dialog title="查看留言" :visible.sync="comment" width="80%">
            <el-table :data="commentlist" border style="width: 100%">
                <el-table-column prop="id" v-if="false"></el-table-column>
                <el-table-column prop="author_name" label="姓名" width="100"></el-table-column>
                <el-table-column prop="comment" label="内容"></el-table-column>
                <el-table-column width="140">
                    <template slot-scope="scope">
                        <div class="opt">
                            <a @click="delcomment(scope.row,scope.$index)">删除</a>
                            <a @click="replay(scope.row)">回复</a>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <p class="paging">
                <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
                    :current-page="current1" :page-sizes="pageSizes1" :page-size="PageSize1"
                    layout="total, sizes, prev, pager, next, jumper" :total="totalCount1" :hide-on-single-page="value1">
                </el-pagination>
            </p>
        </el-dialog>
        <el-dialog title="客服回复" :visible.sync="dialogVisible" width="30%">
            <el-form label-width="80px">
                <el-form-item label="姓名">
                    <el-input type="text" v-model="commentreplyname"></el-input>
                </el-form-item>
                <el-form-item label="回复内容">
                    <el-input type="textarea" v-model="commentreply"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitkefutalk">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                ill_id: '',
                name: '',
                comment: false,
                commentlist: [],
                current1: 1,//默认显示第几页
                totalCount1: 0,// 总条数，根据接口获取数据长度
                pageSizes1: [20],// 个数选择器（可修改）
                PageSize1: 20,// 默认每页显示的条数（可修改）
                value1: false,
                commentid: '',
                replyrow: '',
                dialogVisible: false,
                commentreply: '',
                commentreplyname: ''
            }
        },
        mounted() {
            this.ill_id = this.$route.query.ill_id;
            this.name = this.$route.query.name;
            this.getdata(1, this.PageSize);
        },
        methods: {
            getdata(page, size) {
                this.axios.get("/gu/get_gu_showcusts?page=" + page + "&size=" + size + '&ill_lib_id=' + this.ill_id)
                    .then((res) => {
                        // console.log(res.data);
                        if (res.data.code == 0) {
                            this.list = res.data.result;
                            this.totalCount = res.data.count;
                        } else {
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getdata(currentPage, this.PageSize);
            },
            handleSizeChange1() { },
            handleCurrentChange1(currentPage) {
                this.getcomment(currentPage);
            },
            del(row, index) {
                this.$confirm('此操作将永久删除该病例, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/gu/delete_gu_showcust", this.qs.stringify({ id: row.id }))
                        .then((rs) => {
                            // console.log(rs)
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }

                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            addCase(type, item) {
                if (type == 'new') {
                    this.$router.push({ path: '/illnessManage/illAddCase/illAddCaseDetail', query: { type: type, ill_id: this.ill_id, name: this.name } });
                } else {
                    this.$router.push({ path: '/illnessManage/illAddCase/illAddCaseDetail', query: { type: type, ill_id: this.ill_id, id: item.id, name: this.name } });
                }
            },
            lookLY(row, index) {
                this.comment = true;
                this.commentid = row;
                this.getcomment(1);
            },
            getcomment(page, row) {
                this.axios.get("/showcust/get_gu_showcust_comment?showcust_id=" + this.commentid.id + "&page=" + page + "&size=" + this.PageSize1)
                    .then((rs) => {
                        // console.log(rs)
                        if (rs.data.code == 0) {
                            this.commentlist = rs.data.result;
                            this.totalCount1 = rs.data.count;
                        } else {
                            console.log(rs);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            delcomment(row, index) {
                this.$confirm('此操作将永久删除该评论, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/showcust/delete_gu_showcust_comment", this.qs.stringify({ id: row.id, showcust_id: this.commentid.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.commentlist.splice(index, 1);
                                this.totalCount1--;
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            replay(row) {
                this.dialogVisible = true;
                this.replyrow = row;
                this.commentreply = '';
                this.commentreplyname = '';
            },
            submitkefutalk() {
                let form = {
                    comment: this.commentreply,
                    author_id: sessionStorage.getItem('cust_id'),
                    author_name: this.commentreplyname,
                    headimg: sessionStorage.getItem('headimg'),
                    reply_id: this.replyrow.id,
                    showcust_id: this.replyrow.showcust_id,
                }
                console.log(form);
                this.axios.post("/showcust/insert_gu_showcust_comment", this.qs.stringify(form))
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.$message({
                                message: '回复成功',
                                type: 'success'
                            });
                            this.dialogVisible = false;
                        } else {
                            this.$message(rs.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    }
</script>
<style>
    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
        margin-top: 30px;
    }
</style>